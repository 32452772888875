import styled from 'styled-components';

export const Input = styled.input`
  font-family: ${({ theme }) => theme.font.family.neuville};
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.size.xs};
  padding: 10px;
  border: none;
  border-bottom: 3px solid ${({ theme }) => theme.black};
  border-radius: 0;

  &::placeholder {
    color: ${({ theme }) => theme.black};
  }
`;
